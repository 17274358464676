<template>
  <div class="component">
    Coming Soon...
  </div>
</template>

<script>

export default {
  props: {
    data: Object,
    options: Object,
  },
}
</script>

<style>

</style>
