<template>
  <div class="component">

    <b-card>
      <b-form-group
        label="Category"
        label-for="Category"
      >
        <validation-provider
          #default="{ errors }"
          name="Category"
          rules="required"
        >
          <v-select
            v-model="getCat"
            :options="['Residential', 'Commercial', 'Rental']"
            name="listingCategory"
            :searchable="false"
            :clearable="false"
            multiple
            @input="listingCategorySelect"
          />
          <!-- :multiple="isFeaturedListing" -->
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Status"
        label-for="status"
      >
        <validation-provider
          #default="{ errors }"
          name="Status"
          rules="required"
        >
          <v-select
            v-model="data.general.status"
            :options="['Active', 'Sold/Leased/Rented']"
            name="type"
            :searchable="false"
            :multiple="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Group By Active"
        label-for="group-by-active"
      >
        <validation-provider
          #default="{ errors }"
          name="Group By Acitve"
        >
          <b-form-checkbox
            id="checkbox-1"
            v-model="data.general.groupByActive"
            name="group-by-active"
            :value="true"
            :unchecked-value="false"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Sort by"
        label-for="sort-opt"
      >
        <validation-provider
          #default="{ errors }"
          name="Sort by"
        >
          <v-select
            v-model="data.general.sortBy"
            :options="sortOpt"
            name="type"
            :reduce="opt => opt.value"
            :searchable="false"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

    </b-card>

    <!-- residential -->
    <b-card
      v-if="isShowResidential"
      title="Residential"
    >

      <!-- property type -->

      <b-form-group
        label="Property Type"
        label-for="property-type"
      >
        <validation-provider
          #default="{ errors }"
          name="Property Type"
        >
          <v-select
            v-model="data.general.listing.residential.propertyType"
            :options="options.property_type"
            name="type"
            :searchable="false"
            :multiple="true"
            @input="selectResidentialPropertyType"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- type -->

      <b-form-group
        label="Residential Type"
        label-for="residential-type"
      >
        <validation-provider
          #default="{ errors }"
          name="dwelling Type"
        >
          <v-select
            v-model="data.general.listing.residential.type"
            :options="getResidentialTypeOpt"
            name="type"
            label="type"
            :multiple="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- price  -->

      <div class="row">
        <div class="col-6">
          <b-form-group
            label="Price From"
            label-for="price-from"
          >
            <validation-provider
              #default="{ errors }"
              name="Price From"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <!-- <b-form-input
                  id="price-from"
                  v-model="data.general.listing.residential.priceFrom"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="price-from"
                /> -->

                <v-select
                  v-model="data.general.listing.residential.priceFrom"
                  :options="getResidentialPriceRange"
                  :reduce="price => price.value"
                  @input="comparePriceRange('residential')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            label="Price To"
            label-for="price-to"
          >
            <validation-provider
              #default="{ errors }"
              name="Price To"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <!-- <b-form-input
                  id="price-to"
                  v-model="data.general.listing.residential.priceTo"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="price-to"
                /> -->

                <v-select
                  v-model="data.general.listing.residential.priceTo"
                  :options="getResidentialPriceRange"
                  :reduce="price => price.value"
                  @input="comparePriceRange('residential')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

    </b-card>

    <b-card
      v-if="isShowCommercial"
      title="Commercial"
    >

      <b-form-group
        label="Transaction Type"
        label-for="transaction-type"
      >
        <validation-provider
          #default="{ errors }"
          name="transaction Type"
        >
          <v-select
            v-model="data.general.listing.commercial.transaction_type"
            :options="['For Sale', 'For Lease']"
            name="type"
            label="type"
            multiple
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- type -->

      <b-form-group
        label="Commercial Type"
        label-for="residential-type"
      >
        <validation-provider
          #default="{ errors }"
          name="dwelling Type"
        >
          <v-select
            v-model="data.general.listing.commercial.type"
            :options="getCommercialOpt"
            name="type"
            label="type"
            :multiple="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- price  -->

      <div class="row">
        <div class="col-6">
          <b-form-group
            label="Price From"
            label-for="price-from"
          >
            <validation-provider
              #default="{ errors }"
              name="Price From"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <!-- <b-form-input
                  id="price-from"
                  v-model="data.general.listing.commercial.priceFrom"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="price-from"
                /> -->
                <v-select
                  v-model="data.general.listing.commercial.priceFrom"
                  :options="getCommercialPriceRange"
                  :reduce="price => price.value"
                  @input="comparePriceRange('commercial')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            label="Price To"
            label-for="price-to"
          >
            <validation-provider
              #default="{ errors }"
              name="Price To"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <!-- <b-form-input
                  id="price-to"
                  v-model="data.general.listing.commercial.priceTo"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="price-to"
                /> -->
                <v-select
                  v-model="data.general.listing.commercial.priceTo"
                  :options="getCommercialPriceRange"
                  :reduce="price => price.value"
                  @input="comparePriceRange('commercial')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>
    </b-card>

    <b-card
      v-if="isShowRental"
      title="Rental"
    >
      <!-- type -->

      <b-form-group
        label="Rental Type"
        label-for="residential-type"
      >
        <validation-provider
          #default="{ errors }"
          name="dwelling Type"
        >
          <v-select
            v-model="data.general.listing.rental.type"
            :options="getRentalOpt"
            name="type"
            label="type"
            :multiple="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- price  -->

      <div class="row">
        <div class="col-6">
          <b-form-group
            label="Price From"
            label-for="price-from"
          >
            <validation-provider
              #default="{ errors }"
              name="Price From"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <!-- <b-form-input
                  id="price-from"
                  v-model="data.general.listing.residential.priceFrom"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="price-from"
                /> -->
                <v-select
                  v-model="data.general.listing.rental.priceFrom"
                  :options="getRentalPriceRange"
                  :reduce="price => price.value"
                  @input="comparePriceRange('rental')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            label="Price To"
            label-for="price-to"
          >
            <validation-provider
              #default="{ errors }"
              name="Price To"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <!-- <b-form-input
                  id="price-to"
                  v-model="data.general.listing.residential.priceTo"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="price-to"
                /> -->
                <v-select
                  v-model="data.general.listing.rental.priceTo"
                  :options="getRentalPriceRange"
                  :reduce="price => price.value"
                  @input="comparePriceRange('rental')"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup, BCard, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
// import the component
// import the styles
import vSelect from 'vue-select'
import { required } from '@validations'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect, BFormGroup, ValidationProvider, BCard, BInputGroup, BFormCheckbox,
  },
  props: {
    predefineType: String,
    options: Object,
    data: Object,
    typeOptions: Object,
  },
  data() {
    return {
      selectedCategory: ['Residential'],
      isFeaturedListing: true,
      required,
      sortOpt: [
        {
          value: 'DateDesc',
          label: 'Date (New - Old)',
        },
        {
          value: 'DateAsc',
          label: 'Date (Old - New)',
        },
        {
          value: 'PriceDesc',
          label: 'Price (High - Low)',
        },
        {
          value: 'PriceAsc',
          label: 'Price (Low - High)',
        },
      ],
    }
  },
  computed: {
    getResidentialPriceRange() {
      if (Object.keys(this.options).length) {
        return this.options.price.residential
      }

      return []
    },
    getCommercialPriceRange() {
      if (Object.keys(this.options).length) {
        return this.options.price.commercial
      }

      return []
    },
    getRentalPriceRange() {
      if (Object.keys(this.options).length) {
        return this.options.price.rental
      }

      return []
    },
    getCat: {
      get() {
        return this.data.general.category
      },
      set(val) {
        if (val) {
          if (val.length > 0) {
            this.data.general.category = val
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'Category must be selected at least one', 'danger', 'bottom-right')
          }
        }
      },
    },
    isShowResidential() {
      if (Array.isArray(this.data.general.category) && this.data.general.category.includes('Residential')) {
        return true
      } if (this.data.general.category === 'Residential') {
        return true
      }

      return false
    },
    isShowCommercial() {
      if (Array.isArray(this.data.general.category) && this.data.general.category.includes('Commercial')) {
        return true
      } if (this.data.general.category === 'Commercial') {
        return true
      }

      return false
    },
    isShowRental() {
      if (Array.isArray(this.data.general.category) && this.data.general.category.includes('Rental')) {
        return true
      } if (this.data.general.category === 'Rental') {
        return true
      }

      return false
    },
    getResidentialTypeOpt() {
      let temp = []
      const { propertyType } = this.data.general.listing.residential

      if (this.typeOptions) {
        if (propertyType !== null) {
          if (propertyType.includes('Residential Detached')) {
            temp = temp.concat(this.typeOptions.RD_1)

            // 7
          }

          if (propertyType.includes('Residential Attached')) {
            temp = temp.concat(this.typeOptions.RA_2)

            // 6
          }

          if (propertyType.includes('Land Only')) {
            temp = temp.concat(this.typeOptions.LD_4)

            // 7
          }

          if (propertyType.includes('Multifamily')) {
            temp = temp.concat(this.typeOptions.MF_3)

            // 5
          }

          if (propertyType.length < 1) {
            temp = this.typeOptions.RD_1.concat(this.typeOptions.RA_2).concat(this.typeOptions.LD_4).concat(this.typeOptions.MF_3)
          }
        } else {
          temp = this.typeOptions.RD_1.concat(this.typeOptions.RA_2).concat(this.typeOptions.LD_4).concat(this.typeOptions.MF_3)
        }
      }

      const newTemp = []
      temp.forEach(element => {
        if (!(newTemp.find(o => o.type === element.type))) {
          newTemp.push(element)
        }
      })
      return newTemp
    },
    getCommercialOpt() {
      return this.typeOptions.CM_1
    },
    getRentalOpt() {
      return this.typeOptions.RT_5
    },
  },
  watch: {
    predefineType: {
      deep: true,
      handler(val, oldVal) {
        if (val === 'Featured') {
          this.isFeaturedListing = true
        } else {
          this.isFeaturedListing = false
        }

        if (oldVal !== '' && val !== oldVal) {
          this.data.general.category = []
        }
      },
    },
  },
  methods: {
    comparePriceRange(type) {
      // eslint-disable-next-line no-unused-expressions
      if (this.data.general.listing[type].priceFrom
      && this.data.general.listing[type].priceTo
      && this.data.general.listing[type].priceFrom > this.data.general.listing[type].priceTo) {
        const temp = this.data.general.listing[type].priceFrom
        this.data.general.listing[type].priceFrom = this.data.general.listing[type].priceTo
        this.data.general.listing[type].priceTo = temp
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    selectResidentialPropertyType() {
      const { propertyType } = this.data.general.listing.residential
      if (!propertyType.includes('Residential Detached')) {
        this.data.general.listing.residential.type = this.data.general.listing.residential.type.filter(item => item.class !== 'RD_1')
      }

      if (!propertyType.includes('Residential Attached')) {
        this.data.general.listing.residential.type = this.data.general.listing.residential.type.filter(item => item.class !== 'RA_2')
      }

      if (!propertyType.includes('Land Only')) {
        this.data.general.listing.residential.type = this.data.general.listing.residential.type.filter(item => item.class !== 'LD_4')
      }

      if (!propertyType.includes('Multifamily')) {
        this.data.general.listing.residential.type = this.data.general.listing.residential.type.filter(item => item.class !== 'MF_3')
      }

      if (propertyType === '') {
        this.data.general.listing.residential.type = []
      }
    },
    listingCategorySelect() {
      const selectedCategory = this.getCat

      if (!selectedCategory.includes('Residential')) {
        this.data.general.listing.residential.class = []
        this.data.general.listing.residential.propertyType = []
        this.data.general.listing.residential.type = []
        this.data.general.listing.residential.priceFrom = ''
        this.data.general.listing.residential.priceTo = ''
      }

      if (!selectedCategory.includes('Commercial')) {
        this.data.general.listing.commercial.transaction_type = []
        this.data.general.listing.commercial.type = []
        this.data.general.listing.commercial.priceFrom = ''
        this.data.general.listing.commercial.priceTo = ''
      }

      if (!selectedCategory.includes('Rental')) {
        this.data.general.listing.rental.type = []
      }

      if (selectedCategory.length === 0) {
        this.data.general.listing.residential.propertyType = []
        this.data.general.listing.residential.type = []
        this.data.general.listing.residential.priceFrom = ''
        this.data.general.listing.residential.priceTo = ''
        this.data.general.listing.commercial.transaction_type = []
        this.data.general.listing.commercial.type = []
        this.data.general.listing.commercial.priceFrom = ''
        this.data.general.listing.commercial.priceTo = ''
        this.data.general.listing.rental.type = []
      }
    },
  },
}
</script>

<style>
.v-select {
  width: 100%;
}
</style>
