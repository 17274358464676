<template>
  <div class="component">
    <b-form-group
      label="Schools"
      label-for="schools"
    >
      <validation-provider
        #default="{ errors }"
        name="Schools"
        rules="required"
      >
        <v-select
          v-model="data.schools"
          :options="options.school"
          name="type"
          :multiple="false"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    vSelect, BFormGroup, ValidationProvider,
  },
  props: {
    data: Object,
    options: Object,
  },
  data() {
    return {
      required,
    }
  },
}
</script>

<style>

</style>
