<template>
  <div class="component">
    <b-form-group
      label="Areas"
      label-for="predefine-type"
    >
      <validation-provider
        #default="{ errors }"
        name="Areas"
      >
        <treeselect
          v-model="data.areas"
          :multiple="true"
          :options="options.area"
          placeholder="Select areas / sub-areas..."
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import { required } from '@validations'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BFormGroup, ValidationProvider, Treeselect,
  },
  props: {
    data: Object,
    options: Object,
  },
  data() {
    return {
      required,
    }
  },
}
</script>

<style>

</style>
