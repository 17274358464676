<template>
  <div class="component">
    <b-form-group
      label="Path"
      label-for="path"
    >
      <validation-provider
        #default="{ errors }"
        name="Path"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="path"
            v-model="getPaths"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="path"
            readonly
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <div class="row">
      <div class="col-6">
        <b-form-group
          label="Lat"
          label-for="lat"
        >
          <validation-provider
            #default="{ errors }"
            name="Lat"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="lat"
                v-model="getLats"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="path"
                readonly
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group
          label="Lng"
          label-for="lng"
        >
          <validation-provider
            #default="{ errors }"
            name="Lng"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="lng"
                v-model="getLngs"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="path"
                readonly
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </div>
    <google-map
      ref="mapRef"
      :center="{ lat: 49.2835665, lng: -123.1092417 }"
      :zoom="12"
      style="width: 100%; height: 400px"
    />
    <b-button
      variant="outline-warning"
      class="mt-1"
      @click.prevent="reset"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
// import GoogleMapsApiLoader from 'google-maps-api-loader'
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup, BInputGroup, ValidationProvider, BFormInput, BButton,
  },
  props: {
    mapConfig: Object,
    data: Object,
    // apiKey: String,
  },
  data() {
    return {
      google: null,
      map: null,
    }
  },
  computed: {
    getPaths: {
      get() {
        return JSON.stringify(this.data.polygon.path)
      },
      set(val) {
        console.log(val)
      },
    },
    getLats: {
      get() {
        return JSON.stringify(this.data.polygon.lat)
      },
      set(val) {
        console.log(val)
      },
    },
    getLngs: {
      get() {
        return JSON.stringify(this.data.polygon.lng)
      },
      set(val) {
        console.log(val)
      },
    },
  },
  mounted() {
    const self = this

    this.$nextTick(() => {
      this.$refs.mapRef.$mapPromise.then(() => {
        if (self.data.polygon && self.data.polygon.path.length > 0) {
          this.preloadPolygon()
        } else {
          this.initMap()
        }
      })
    })
  },
  methods: {
    reset() {
      if (this.polygon) {
        this.polygon.setMap(null)
        this.lat = {}
        this.lng = {}
        this.path = []

        this.dm.setOptions({
          drawingControl: true,
        })
      } else {
        this.preload.setMap(null)

        this.initMap()
      }
    },
    preloadPolygon() {
      const pos = []
      this.data.polygon.path.forEach(singlePath => {
        pos.push({ lat: singlePath.lat, lng: singlePath.lng })
      })
      // Construct the polygon.
      this.preload = new window.google.maps.Polygon({
        paths: pos,
      })
      this.preload.setMap(this.$refs.mapRef.$mapObject)
    },
    initMap() {
      this.dm = new window.google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon'],
        },
      })
      this.dm.setMap(this.$refs.mapRef.$mapObject)

      window.google.maps.event.addListener(
        this.dm,
        'overlaycomplete',
        event => {
          const path = event.overlay.getPath().getArray()
          this.polygon = event.overlay

          path.forEach(temp => {
            this.data.polygon.path.push({ lat: temp.lat(), lng: temp.lng() })
          })

          this.dm.setOptions({
            drawingMode: null,
            drawingControl: false,
          })

          this.data.polygon.lat.max = Math.max.apply(
            Math,
            this.data.polygon.path.map(o => {
              return o.lat
            }),
          )

          this.data.polygon.lng.max = Math.max.apply(
            Math,
            this.data.polygon.path.map(o => {
              return o.lng
            }),
          )

          this.data.polygon.lat.min = Math.min.apply(
            Math,
            this.data.polygon.path.map(o => {
              return o.lat
            }),
          )

          this.data.polygon.lng.min = Math.min.apply(
            Math,
            this.data.polygon.path.map(o => {
              return o.lng
            }),
          )
        },
      )
    },
  },
}
</script>

<style>

</style>
