<template>
  <div class="component">
    <b-form-group
      label="Type"
      label-for="type"
    >
      <validation-provider
        #default="{ errors }"
        name="type"
      >
        <v-select
          v-model="data.featured"
          :options="['Featured', 'Office']"
          name="type"
          :searchable="false"
          :clearable="false"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    vSelect, BFormGroup, ValidationProvider,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      options: [{
        id: 'a',
        label: 'a',
        children: [{
          id: 'aa',
          label: 'aa',
        }, {
          id: 'ab',
          label: 'ab',
        }],
      }, {
        id: 'b',
        label: 'b',
      }, {
        id: 'c',
        label: 'c',
      }],
      required,
    }
  },
}
</script>

<style>

</style>
